import styled from "styled-components";
import IconButton from "components/IconButton";
import theme from "constants/theme";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const OwnerLabel = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: ${theme.color.mainLight};
  font-weight: 400;
  margin-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and ${theme.device.mobile} {
    max-width: 300px;
  }
`;

export const CopyBtn = styled(IconButton)`
  width: 32px;
  height: 32px;
  transition: 0.3s;

  &:hover {
    opacity: 0.5;
  }
`;
