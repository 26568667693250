import React, { VFC } from "react";
import { Wrapper, Title } from "./style";

interface Props
  extends Pick<
    React.HTMLProps<HTMLTextAreaElement>,
    "onChange" | "value" | "name"
  > {
  label: string;
}

/**
 * Description - a component that stores a textarea and names for it.
 *
 * @param title - textarea title
 * @returns - TSX Elememt (Description component)
 */

const Description: VFC<Props> = (props) => {
  const { label, ...textareaProps } = props;

  return (
    <>
      <Title>{label}</Title>
      <Wrapper {...textareaProps} />
    </>
  );
};

export default Description;
