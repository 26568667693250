import { VFC } from "react";
import { ADD_ICON } from "constants/images";
import { Wrapper, Label, AddBtn } from "./style";

type Props = {
  handleClick: () => void;
};

const AddBlock: VFC<Props> = (props) => {
  const { handleClick } = props;

  return (
    <Wrapper>
      <Label>
        Press{" "}
        <AddBtn color="white" onClick={handleClick}>
          <img alt="#" src={ADD_ICON} />
        </AddBtn>{" "}
        button to add new shareholder
      </Label>
    </Wrapper>
  );
};

export default AddBlock;
