import React, { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { DISCONNECT } from "constants/images";
import { useAuth } from "hooks";
import { shortenAddress } from "utils/shortenAddress";
import { Wrapper, AccountImg, Account } from "./style";

type Props = {
  onClick: () => void;
  className?: string;
};

/**
 * ConnectButton - click button connecting to our metamask account
 * contains button element and viewing your account
 *
 * @param onClick - a function to open a modal window
 * @param className - style for the component
 * @param onClick - onClick function
 *
 * @returns - TSX element (Connect Button component)
 */

const ConnectButton: VFC<Props> = (props) => {
  const { account, isAuthorized, logOut } = useAuth();

  const { onClick, className } = props;

  const { t } = useTranslation();

  const accountNameAbbr = useMemo(() => {
    return account ? shortenAddress(account) : "";
  }, [account]);

  if (!isAuthorized) {
    return (
      <Button className={className} color="white" onClick={onClick}>
        {t("connectBtn")}
      </Button>
    );
  }

  return (
    <Wrapper className={className}>
      <Account>{accountNameAbbr}</Account>
      <AccountImg alt="#" onClick={logOut} src={DISCONNECT} />
    </Wrapper>
  );
};

export default React.memo(ConnectButton);
