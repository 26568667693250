import { VFC, useState, useCallback, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import GuardedButton from "components/GuardedButton"; // Move this before the local imports
import { useDeploy, useToast } from "hooks"; // Move this before the local imports
import { Api } from "typings"; // Move this before the local imports
import SuccessBlock from "./components/SuuccessBlock"; // Move this before the local imports
import Table from "./components/Table"; // Move this before the local imports
import { IpfsInput, FlexWrapper } from "./style"; // Move this before the local imports
import { Shareholder } from "./types"; // Move this before the local imports

import CopyAddress from "../../components/CopyAddress";

type Props = {
  contract: Api.Contract;
};

const Deploy: VFC<Props> = (props) => {
  const { contract } = props;

  const [ipfsValue, setIpfsValue] = useState<string>("");

  const [shareholders, setShareholders] = useState<Shareholder[]>([]); // Initialize with an empty array

  const [isDeployed, setIsDeployed] = useState<boolean>(false);

  const [deployAddress, setDeployAddress] = useState<string>("");

  const { t } = useTranslation();

  const deploy = useDeploy();

  const toast = useToast();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIpfsValue(e.target.value);
  };

  const totalShare = shareholders.reduce(
    (sum, shareholder) => sum + Number(shareholder.share),
    0
  );

  const handleSubmit = useCallback(async () => {
    if (totalShare !== 100) {
      toast.error({
        message: t("errors.lessHundred"),
        title: t("errors.error"),
      });

      return;
    }

    const _shares: number[] = [];
    const _shareholders: string[] = [];

    shareholders.forEach(({ address, share }) => {
      _shares.push(share * 100);
      _shareholders.push(address);
    });

    try {
      const deployFunc = await deploy(contract, {
        _shareholders,
        _shares,
        _ipfs: ipfsValue,
      });

      setDeployAddress(deployFunc!.address);
      setIsDeployed(true);
    } catch (error) {
      toast.error({
        message: `${error}`,
        title: `${t("deploy.error.title")}`,
      });
    }
  }, [contract, deploy, ipfsValue, shareholders, t, toast, totalShare]);

  const handleTableModeChange = useCallback(() => {
    setIsDeployed(false);
  }, []);

  return (
    <>
      <CopyAddress address={contract!.owner} />
      <FlexWrapper>
        <IpfsInput
          onChange={handleChange}
          placeholder="ipfs://"
          value={ipfsValue}
        />
        <GuardedButton color="gradient" onClick={handleSubmit}>
          {t("junaSimple.deploy")}
        </GuardedButton>
      </FlexWrapper>
      {isDeployed && <SuccessBlock address={deployAddress} />}
      <Table
        onChange={setShareholders}
        onModeChange={handleTableModeChange}
        shareholders={shareholders}
        totalShare={totalShare}
      />
    </>
  );
};

export default Deploy;
