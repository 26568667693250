import { ApiRoutes } from "constants/index";
import { Api } from "typings";
import { axiosInstance } from "./axios-instance";

const getContracts = async (params: { category?: number; jurisdiction?: number; page: number; size: number }) => {
  const { data } = await axiosInstance.get<Api.PaginatedResponse<Api.Contract>>(ApiRoutes.ContractList, { params });
  return data;
};

const getContractById = async (contractId: number) => {
  const { data } = await axiosInstance.get<Api.Contract>(`${ApiRoutes.ContractList}${contractId}`);
  return data;
};

const createContract = async (contractData: Api.ContractMutateData) => {
  const { data } = await axiosInstance.post<Api.Contract>(`${ApiRoutes.ContractList}`, contractData);
  return data;
};

const updateContract = async (contractId: number, contractData: Api.ContractMutateData) => {
  const { data } = await axiosInstance.put<Api.Contract>(`${ApiRoutes.ContractList}${contractId}/`, contractData);
  return data;
};

const getCategories = async () => {
  const { data } = await axiosInstance.get<Api.Category[]>(ApiRoutes.CategoryList);
  return data;
};

const getJurisdictions = async () => {
  const { data } = await axiosInstance.get<Api.Jurisdiction[]>(ApiRoutes.JurisdictionList);
  return data;
};

export const api = {
  getJurisdictions,
  getCategories,
  getContracts,
  getContractById,
  createContract,
  updateContract,
};
