import React, { Suspense } from "react";
import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import App from "App";
import Loading from "components/Loading";
import { StyledToastContainer } from "components/Toast";
import { TOAST_AUTO_CLOSE_TIME, NetworkContextName } from "constants/index";
import { AuthProvider } from "context";
import getLibrary from "utils/getLibrary";
import "./i18n";

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: Nunito, serif;
  }
  body {
    margin:0;
    padding:0;
  }
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <Router>
                <App />
              </Router>
            </AuthProvider>
          </QueryClientProvider>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </Suspense>
    <StyledToastContainer
      autoClose={TOAST_AUTO_CLOSE_TIME}
      hideProgressBar={true}
    />
    <GlobalStyle />
  </React.StrictMode>,
  document.getElementById("root")
);
