import { useMemo } from "react";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { api } from "api";
import Container from "components/Container";
import ContractCard from "components/ContractCard";
import Loader from "components/Loader";
import {
  FIRST_STEP,
  JUNA_LOGO,
  SECOND_STEP,
  SUPERAPP_LOGO,
  THIRD_STEP,
} from "constants/images";
import { useToast } from "hooks";
import Products from "./components/Products";
import Steps from "./components/Steps/index";
import Subscribe from "./components/Subscribe";
import { ItemsContainer, Wrapper, More, MoreTitle } from "./style";

const products = [
  { id: 1, src: JUNA_LOGO },
  { id: 2, src: SUPERAPP_LOGO },
];

const CONTRACTS_AMOUNT = 8;

/**
 * The Home page contains presentation information about the project.
 *
 * @returns - TSX Element (Home page)
 */

export const Home = () => {
  const { t } = useTranslation();

  const toast = useToast();

  const { isLoading, isSuccess, data } = useQuery(
    "contracts",
    () => api.getContracts({ page: 1, size: CONTRACTS_AMOUNT }),
    {
      onError: (err: AxiosError) => {
        toast.error({
          message: err.message,
          title: err.name,
        });
      },
    }
  );

  const steps = useMemo(
    () => [
      {
        label: t("mainPage.steps.1step"),
        description: t("mainPage.steps.description1"),
        src: FIRST_STEP,
      },
      {
        label: t("mainPage.steps.2step"),
        description: t("mainPage.steps.description2"),
        src: SECOND_STEP,
      },
      {
        label: t("mainPage.steps.3step"),
        description: t("mainPage.steps.description3"),
        src: THIRD_STEP,
      },
    ],
    [t]
  );

  return (
    <>
      <Wrapper>
        <Container>
          {isLoading && <Loader />}
          {isSuccess && (
            <ItemsContainer>
              {data!.results.map((contract) => (
                <ContractCard
                  key={contract.id}
                  country={contract.jurisdiction.name}
                  id={contract.id}
                  title={contract.name}
                />
              ))}
            </ItemsContainer>
          )}
        </Container>
      </Wrapper>
      <More>
        <MoreTitle>{t("mainPage.more")}</MoreTitle>
      </More>
      <Container>
        <Steps steps={steps} />
        <Products products={products} />
        <Subscribe />
      </Container>
    </>
  );
};
