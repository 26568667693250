import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useToast } from "hooks";

export const useCopyToClipboard = () => {
  const { t } = useTranslation();

  const toast = useToast();

  const copyToClipBoard = useCallback(
    async (text: string) => {
      try {
        await navigator.clipboard.writeText(text);
        toast.success({
          message: `${t("clipboard.success.message")}`,
          title: `${t("clipboard.success.title")}`,
        });
      } catch {
        toast.error({
          message: `${t("clipboard.error.message")}`,
          title: `${t("clipboard.success.title")}`,
        });
      }
    },
    [t, toast]
  );

  return copyToClipBoard;
};
