import { VFC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import IconButton from "components/IconButton";
import { COPY_ICON, PENCIL_ICON } from "constants/images";
import { AppRoutes } from "constants/index";
import { useCopyToClipboard } from "hooks";

import {
  ContractWrapper,
  AdressTitle,
  FlexWrapper,
  IpfsBtn,
  BtnWrapper,
  Description,
  DescriptionItem,
} from "./style";

type Props = {
  name: string;
  audit?: string;
  description: string;
  owner: string;
  ipfs?: string;
  jurisdicrion: string;
  contractId: number;
};

/**
 * ContractInfo - contains information about the selected contract
 *
 * @param name - contract name
 * @param audit - contract audit
 * @param description - contract description
 * @param owner - contract owner
 * @param ipfs - contract ipfs
 * @param jurisdicrion - contract jurisdicrion
 *
 * @returns - TSX Element (Contract Info component)
 */

const ContractInfo: VFC<Props> = (props) => {
  const { name, audit, description, owner, ipfs, jurisdicrion, contractId } =
    props;

  const { t } = useTranslation();

  const copyToClipboard = useCopyToClipboard();

  const descItems = useMemo(
    () => [
      { label: description },
      { label: owner },
      { label: audit || null },
      { label: jurisdicrion },
    ],
    [audit, owner, description, jurisdicrion]
  );

  return (
    <ContractWrapper>
      <FlexWrapper>
        <AdressTitle>{name}</AdressTitle>
        <BtnWrapper>
          <IconButton color="gray">
            <Link to={`${AppRoutes.ContractTemplate}/${contractId}`}>
              <img alt="#" src={PENCIL_ICON} />
            </Link>
          </IconButton>
          <IconButton color="gray" onClick={() => copyToClipboard(ipfs || "")}>
            <img alt="#" src={COPY_ICON} />
          </IconButton>
          <IpfsBtn href={`https://ipfs.io/ipfs/${ipfs}`} target="_blank">
            {t("contractPage.info.ipfs")}
          </IpfsBtn>
        </BtnWrapper>
      </FlexWrapper>
      <Description>
        {descItems.map(({ label }) => (
          <DescriptionItem key={label}>{label}</DescriptionItem>
        ))}
      </Description>
    </ContractWrapper>
  );
};

export default ContractInfo;
