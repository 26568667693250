import styled from "styled-components";
import IconButton from "components/IconButton";
import theme from "constants/theme";

export const Wrapper = styled.div`
  border-radius: 12px;
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.borderGray};
  box-shadow: 0px 4px 8px rgba(226, 226, 238, 0.48);
  padding: 25px;
  margin: 16px 0 16px 0px;
`;

export const Label = styled.span`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  color: ${theme.color.mainLight};
`;

export const Title = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 18px;
  color: ${theme.color.green};
`;

export const CopyBtn = styled(IconButton)`
  width: 32px;
  height: 32px;
  transition: 0.3s;

  &:hover {
    opacity: 0.5;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;
