import { useCallback, useContext, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { injected } from "connectors";
import { AuthContext } from "context";
import { useToast } from "hooks";
import { App } from "typings";

const DISCONNECT = "disconnect";

type UseAuth = () => App.AuthContext & {
  account: string | null | undefined;
  isAuthorized: boolean;
  logIn: () => Promise<void>;
  logOut: () => void;
};

export const useAuth: UseAuth = () => {
  const { isProviderModalActive, toggleProviderModal } =
    useContext(AuthContext);

  const { activate, active, account, deactivate, error } = useWeb3React();

  const toast = useToast();

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (
        isAuthorized &&
        !active &&
        !error &&
        localStorage.getItem(DISCONNECT) === null
      ) {
        activate(injected);
      }
    });
  }, [active, error, activate]);

  const logIn = useCallback(async () => {
    await activate(injected, (error) => {
      toast.error({
        message: error.message,
        title: error.name,
      });
    });

    localStorage.removeItem(DISCONNECT);
  }, [activate, toast]);

  const logOut = useCallback(() => {
    deactivate();

    localStorage.setItem(DISCONNECT, "true");
  }, [deactivate]);

  return {
    account,
    isAuthorized: active,
    isProviderModalActive,
    logIn,
    logOut,
    toggleProviderModal,
  };
};
