import { VFC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { COPY_ADRESS_ICON } from "constants/images";
import { useCopyToClipboard } from "hooks";
import { Wrapper, Title, Label, FlexWrapper, CopyBtn } from "./style";

type Props = {
  address: string;
};

const SuccessBlock: VFC<Props> = (props) => {
  const { address } = props;

  const copyToClipboard = useCopyToClipboard();

  const { t } = useTranslation();

  const handleCopyClick = useCallback(() => {
    copyToClipboard(address);
  }, [address, copyToClipboard]);

  return (
    <Wrapper>
      <Title>{t("deploy.success.title")}</Title>
      <FlexWrapper>
        <Label>{address}</Label>
        <CopyBtn color="gray" onClick={handleCopyClick}>
          <img alt="#" src={COPY_ADRESS_ICON} />
        </CopyBtn>
      </FlexWrapper>
    </Wrapper>
  );
};

export default SuccessBlock;
