import { VFC, useCallback } from "react";
import { COPY_ADRESS_ICON } from "constants/images";
import { useCopyToClipboard } from "hooks";
import { OwnerLabel, Wrapper, CopyBtn } from "./style";

type Props = {
  address: string;
};

const CopyAddress: VFC<Props> = (props) => {
  const { address } = props;

  const copyToClipboard = useCopyToClipboard();

  const handleCopyClick = useCallback(() => {
    copyToClipboard(address);
  }, [address, copyToClipboard]);

  return (
    <Wrapper>
      <OwnerLabel>{address}</OwnerLabel>

      <CopyBtn color="gray" onClick={handleCopyClick}>
        <img alt="#" src={COPY_ADRESS_ICON} />
      </CopyBtn>
    </Wrapper>
  );
};

export default CopyAddress;
