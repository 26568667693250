import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { Card, Title, Option, Label, Value, StyledLink } from "./style";

type Props = {
  className?: string;
  title: string;
  country: string;
  id: number;
};

/**
 * Contract Component - It takes four key parameters to display information about the contract.
 *
 * @param classname - parameter to display the item correctly in the grid
 * @param title - name of the contract
 * @param country - the name of the country
 *
 * @returns - TSX element (Contract Card component)
 */

const ContractCard: VFC<Props> = (props) => {
  const { className, title, country, id } = props;

  const { t } = useTranslation();

  return (
    <StyledLink to={`/contracts/${id}`}>
      <Card className={className}>
        <Title>{title}</Title>
        <Option>
          <Label>{t("mainPage.contractCard.country")}</Label>
          <Value>{country}</Value>
        </Option>
      </Card>
    </StyledLink>
  );
};

export default ContractCard;
