import { FC, ReactNode } from "react";
import { InputWrapper, Input } from "./style";

type Props = {
  children?: ReactNode;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  id?: any;
  name?: string;
  onSubmit?: any;
  type?: string;
};

/**
 * ContractInput - input component created for use in a contract.
 *
 * @param placeholder - input placeholder
 * @param onChange - onChange function for input
 * @param value - input value
 * @param id - inpud id
 * @param name - input name
 * @param type - input type as html attribute
 *
 * @returns - TSX Element (Contract Input component)
 */

const ContractInput: FC<Props> = (props) => {
  const { placeholder, onChange, value = "", id, name, type } = props;

  return (
    <InputWrapper>
      <Input
        id={id}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        value={value}
      />
    </InputWrapper>
  );
};

export default ContractInput;
