import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Button from "components/Button";
import { AppRoutes } from "constants/index";
import { Label, Wrapper } from "./style";

/**
 * Subscribe - сomponent with information and a link to the smart contracts catalog page
 *
 * @returns - TSX Element (Subscribe component)
 */

const Subscribe = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onClick = () => {
    history.push(AppRoutes.Catalog);
  };

  return (
    <Wrapper>
      <Label>{t("mainPage.subscribe.label")}</Label>
      <Button color="red" onClick={onClick}>
        {t("mainPage.subscribe.btn")}
      </Button>
    </Wrapper>
  );
};

export default Subscribe;
