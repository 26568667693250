import styled from "styled-components";
import theme from "constants/theme";
import { IconBtnColor } from "./types";

const getBorder = (color: IconBtnColor) => {
  switch (color) {
    case "white":
      return `1px solid ${theme.color.borderGray}`;
    default:
      return "none";
  }
};

const getBackgroundColor = (color: IconBtnColor) => {
  switch (color) {
    case "red":
      return `${theme.color.orangeRed}`;
    case "gray":
      return `${theme.color.backgroundGray}`;
    default:
      return `${theme.color.white}`;
  }
};

const getBoxShadow = (color: IconBtnColor) => {
  switch (color) {
    case "white":
      return `0px 4px 8px rgba(226, 226, 238, 0.48)`;
    default:
      return "none";
  }
};

export const Wrapper = styled.button<{ color: IconBtnColor }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: ${({ color }) => getBorder(color)};
  background-color: ${({ color }) => getBackgroundColor(color)};
  box-shadow: ${({ color }) => getBoxShadow(color)};
`;
