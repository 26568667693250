import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { AppRoutes } from "constants/index";
import { LinkWrapper, StyledLink } from "./style";

type Props = {
  children: ReactNode;
};

/**
 * Breadcrumbs - component that displays the path from the Home page to the current one
 *
 * @param children - current page title
 * @returns - TSX Element (Breadcrumbs component)
 */

const Breadcrumbs: FC<Props> = (props) => {
  const { children } = props;

  const { t } = useTranslation();

  return (
    <LinkWrapper>
      <StyledLink to={AppRoutes.Home}>{t("contractPage.home")} /</StyledLink>{" "}
      {children}
    </LinkWrapper>
  );
};

export default Breadcrumbs;
