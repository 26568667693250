import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "constants/theme";

export const LinkWrapper = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.color.mainLight};
  margin-top: 10px;
`;

export const StyledLink = styled(Link)`
  color: ${theme.color.mainLight};
  text-decoration: none;
`;
