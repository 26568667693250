import styled from "styled-components";
import theme from "constants/theme";

export const Case = styled.div`
  width: 50%;
  padding 32px 0px;
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.borderGray};
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(226, 226, 238, 0.48);
  max-height: 262px;
  overflow: hidden;
  overflow-x: scroll;

  @media screen and ${theme.device.laptop} {
    width: 100%;
  }
`;

export const Template = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0 32px;

  @media screen and ${theme.device.tablet} {
    width: 452px;
    margin: 0 auto 8px auto;
  }
`;

export const Label = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
`;

export const TextWrapper = styled.div`
  display: flex;
  max-width: 194px;
  width: 100%;
  justify-content: space-between;
`;

export const Line = styled.div`
  width: 100%;
  background-color: ${theme.color.borderGray};
  height: 1px;
`;

export const Text = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  &:nth-last-child(1) {
    color: ${theme.color.blue};
  }
`;

export const Name = styled(Text)`
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HistoryItem = styled.div`
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
`;

export const Overflow = styled.div`
  max-height: 171px;
  height: 100%;
  overflow: auto;
  padding: 0 32px;

  @media screen and ${theme.device.tablet} {
    width: 452px;
    margin: 0 auto;
  }
`;
