import { Contract } from "ethers";
import styled from "styled-components";
import ConnectButton from "components/ConnectButton";
import IconButton from "components/IconButton";
import Select from "components/Select";
import theme from "constants/theme";

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;

  @media screen and ${theme.device.laptop} {
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and ${theme.device.tablet} {
    align-items: center;
  }
`;

export const Title = styled.span`
  font-weight: 800;
  font-size: 48px;
  line-height: 45px;
  letter-spacing: 0.02em;
  margin-right: 32px;
  margin-bottom: 5px;
  color: ${theme.color.mainDark};

  @media screen and ${theme.device.tablet} {
    margin-bottom: 20px;
  }

  @media screen and ${theme.device.tablet} {
    margin-bottom: 15px;
    margin-right: 0;
    text-align: center;
    font-size: 40px;
  }
`;

export const Jurisdiction = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: ${theme.color.mainLight};

  @media screen and ${theme.device.tablet} {
    margin-bottom: 15px;
  }
`;

export const VerticalLine = styled.span`
  width: 1px;
  height: 48px;
  background: ${theme.color.mainLight};
`;

export const Balance = styled.span`
  font-weight: 600;
  font-size: 48px;
  margin-left: 32px;
  color: ${theme.color.red};
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and ${theme.device.tablet} {
    flex-direction: column;
  }
`;

export const SelectWrapper = styled.div`
  width: 300px;
`;

export const StyledSelect = styled(Select)<{ onChange?: any }>`
  .react-select__control {
    background-color: ${theme.color.white};
    border: 1px solid ${theme.color.borderGray};
  }
`;

export const CopyTextIcon = styled(IconButton)`
  width: auto;
  border-radius: 16px;
  padding: 0px 2px;
  transition: 0.3s;

  &:hover {
    opacity: 0.5;
  }
`;

export const ShareWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
  cursor: pointer;
  background: ${theme.color.backgroundGray};
  border-radius: 16px;
`;

export const Ipfs = styled.div`
  background: ${theme.color.backgroundGray};
  border-radius: 16px;
  margin: 30px 0px;
`;

export const IpfsText = styled.div`
  color: ${theme.color.mainLight};
  padding: 30px;
  font-size: 16px;

  @media screen and ${theme.device.tablet} {
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media screen and ${theme.device.mobile} {
    width: 200px;
  }
`;

export const DescriptionBlock = styled(Ipfs)`
  margin: 0px 0px 0px 0px;
  display: flex;
`;

export const StyledDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 20px 0px;
`;

export const DescriptionWrap = styled(StyledDescription)`
  gap: 20px;
  align-items: start;

  @media screen and ${theme.device.laptop} {
    flex-direction: column;
  }
`;

export const DescriptionTitle = styled.div`
  font-weight: 800;
  font-size: 24px;
`;

export const DescriptionText = styled.div`
  color: ${theme.color.mainLight};
  padding: 30px;
  font-size: 16px;
`;

export const ShareBtn = styled.button`
  background: ${theme.color.backgroundGray};
  color: ${theme.color.black};
  border: 0;
  border-radius: 16px;
  font-size: 16px;
  padding: 10px 30px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.5;
  }
`;

export const StyledConnectBtn = styled(ConnectButton)`
  margin: 20px 0px;
`;

export const Input = styled.input<{ contract: Contract | null }>`
  border: 0;
  background: ${theme.color.backgroundGray};
  outline: 0;
  width: ${({ contract }) => (contract ? "40%" : "100%")};
  color: ${theme.color.mainLight};
  padding: 20px 20px;
  border-radius: 16px;
  margin: 10px 0;
  transition: 0.2s;

  @media screen and ${theme.device.tablet} {
    width: 100%;
  }
`;

export const Table = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin: 20px 0px 0px 0px;

  @media screen and ${theme.device.tablet} {
    flex-direction: column;
    gap: 50px;
  }
`;

export const Wrapper = styled.div`
  border: 1px solid ${theme.color.borderGray};
  border-radius: 16px;
  padding: 20px;
  width: 100%;
  flex: 0 0.5 auto;
`;

export const TitleTable = styled.div`
  font-weight: 800;
  font-size: 24px;
`;

export const TableWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export const AddressWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const AdressColor = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  clip-path: circle(50%);
  height: 1em;
  width: 1em;
`;

export const AdressTitle = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and ${theme.device.laptop} {
    max-width: 150px;
  }
`;

export const Line = styled.div`
  width: 100%;
  background-color: ${theme.color.borderGray};
  height: 1px;
  margin: 15px 0px;
`;

export const Error = styled.div`
  color: ${theme.color.red};
  text-align: center;
  margin: 25px 0px;
  font-size: 20px;
`;

export const WrapCopyBtn = styled.div`
  @media screen and ${theme.device.tablet} {
    padding-top: 15px;
  }
`;
