import { FC } from "react";
import { useTranslation } from "react-i18next";
import Button, { Props } from "components/Button";
import { useAuth } from "hooks";

/**
 * GuardedButton component that allows us to block the capabilities of any button if the user is not authorized
 *
 * @param props - component properties
 * @param props.color - button color
 * @param props.type - button type as html attribute
 * @param props.onClick - onClick handler
 * @param props.children - in children we accept the button text
 *
 * @returns - TSX element (Guarded Button component)
 */

const GuardedButton: FC<Props> = (props) => {
  const { isAuthorized, toggleProviderModal } = useAuth();

  const { children, className, color, onClick, type } = props;

  const { t } = useTranslation();

  return (
    <Button
      className={className}
      color={color}
      onClick={!isAuthorized ? toggleProviderModal : onClick}
      type={!isAuthorized ? "button" : type}
    >
      {!isAuthorized ? t("connectBtn") : children}
    </Button>
  );
};

export default GuardedButton;
