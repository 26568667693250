import { VFC, Fragment, useMemo } from "react";
import { Api } from "typings";
import { Case, Template, Label, TextWrapper, Line, Text, HistoryItem, Overflow, Name } from "./style";

type Props = {
  versions?: Api.Version[];
};

const VersionTable: VFC<Props> = (props) => {
  const { versions } = props;

  const versionHistory = useMemo(() => {
    return versions?.map((item) => ({
      name: item.ipfs,
      date: item.createdAt,
      action: "Changed",
    }));
  }, [versions]);

  return (
    <Case>
      <Template>
        <Label>Address</Label>
        <TextWrapper>
          <Label>Date</Label>
          <Label>Action</Label>
        </TextWrapper>
      </Template>
      <Overflow>
        {versionHistory?.map(({ name, date, action }) => (
          <Fragment key={name}>
            <Line />
            <HistoryItem>
              <Name>{name}</Name>
              <TextWrapper>
                <Text>{date}</Text>
                <Text>{action}</Text>
              </TextWrapper>
            </HistoryItem>
          </Fragment>
        ))}
      </Overflow>
    </Case>
  );
};

export default VersionTable;
