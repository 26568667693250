import styled from "styled-components";
import theme from "constants/theme";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
`;

export const Account = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 38px;
  color: ${theme.color.mainDark};
  background: ${theme.color.backgroundGray};
  height: 27px;
  border: none;

  @media screen and ${theme.device.tablet} {
    width: 130px;
  }
`;

export const AccountImg = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;
