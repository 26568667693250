import React, { useMemo } from "react";
import { ToastOptions, toast } from "react-toastify";
import { Toast } from "components/Toast";

type ToastFunction = (params: {
  message: string;
  title?: string;
  options?: ToastOptions;
}) => void;

type UseToast = () => {
  error: ToastFunction;
  info: ToastFunction;
  success: ToastFunction;
};

export const useToast: UseToast = () => {
  return useMemo(
    () => ({
      error: ({ options, ...toastProps }) => {
        toast(<Toast type="error" {...toastProps} />, options);
      },
      info: ({ options, ...toastProps }) => {
        toast(<Toast type="info" {...toastProps} />, options);
      },
      success: ({ options, ...toastProps }) => {
        toast(<Toast type="success" {...toastProps} />, options);
      },
    }),
    []
  );
};
