export const LOGO = "/images/logo.png";
export const LIGHT_ICON = "/images/light.svg";
export const NIGHT_ICON = "/images/night.svg";
export const TWITTER_ICON = "/images/twitterIcon.svg";
export const TELEGRAM_ICON = "/images/telegramIcon.svg";
export const DISCORD_ICON = "/images/discordIcon.svg";
export const DISCONNECT = "/images/disconnect.svg";
export const SEARCH_ICON = "/images/searchIcon.svg";
export const METAMASK_ICON = "/images/providers/metamask.svg";
export const WALLETCONNECT_ICON = "images/providers/walletconnect.svg";
export const TRUSTWALLET_ICON = "images/providers/trustwallet.svg";
export const MATHWALLET_ICON = "images/providers/mathwallet.svg";
export const BSCWALLET_ICON = "images/providers/bscwallet.svg";
export const CLOSE_ICON = "/images/close-icon.svg";
export const PREV_NEXT_BTN = "/images/prevNextBtnIcon.svg";
export const HAMBURGER_ICON = "/images/hamburgerIcon.svg";
export const HAMBURGER_CLOSE_ICON = "/images/hamburgerCloseIcon.svg";
export const TOAST_ERROR_ICON = "/images/toast/danger.svg";
export const TOAST_SUCCESS_ICON = "/images/toast/successfully.svg";
export const TOAST_INFO_ICON = "/images/toast/info.svg";
export const MORE = "/images/more.svg";
export const MORE_ARROW = "/images/moreArrow.svg";
export const PRODUCT_ICON = "/images/productIcon.png";
export const COPY_ICON = "/images/copyIcon.svg";
export const TEXT_ICON = "/images/textIcon.svg";
export const INPUT_ARROW = "/images/inputArrow.svg";
export const JUNA_LOGO = "/images/products/juna.png";
export const SUPERAPP_LOGO = "/images/products/superapp.png";
export const PENCIL_ICON = "/images/pencil-icon.svg";
export const FIRST_STEP = "/images/steps/first.png";
export const SECOND_STEP = "/images/steps/second.png";
export const THIRD_STEP = "/images/steps/third.png";
export const COPY_ADRESS_ICON = "/images/copyAdressIcon.svg";
export const EDIT_ICON = "/images/editIcon.svg";
export const CHECK_MARK_ICON = "/images/checkMarkIcon.svg";
export const COPY_TEXT_ICON = "/images/copyTextIcon.svg";
export const ADD_ICON = "/images/addIcon.svg";
