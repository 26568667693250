import styled from "styled-components";
import theme from "constants/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.color.backgroundGray};
  padding: 20px;
  border-radius: 12px;
  margin: 0px 0px 20px 0px;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 20px 0px;
  text-overflow: ellipsis;

  @media screen and ${theme.device.tablet} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Value = styled.div`
  color: ${theme.color.mainLight};
  width: 70%;
  text-align: right;
  overflow-wrap: break-word;

  @media screen and ${theme.device.tablet} {
    width: 100%;
    text-align: center;
  }
`;
