import { VFC } from "react";
import { ToastIcon, ToastType } from "./constants";
import {
  StyledToastContainer,
  Img,
  Message,
  Status,
  Title,
  Wrapper,
  MessageWrapper,
} from "./style";

type Props = {
  message: string;
  title?: string;
  type: `${ToastType}`;
};

/**
 * Toast - notification component
 *
 * @param message - toast message
 * @param title - toast title
 * @param type - toast type
 * @returns - TSX element (Toast component)
 */

const Toast: VFC<Props> = (props) => {
  const { message, title, type = ToastType.Info } = props;

  return (
    <Wrapper>
      <Status toastType={type}>
        <Img alt="#" src={ToastIcon[type]} />
      </Status>
      <MessageWrapper>
        <Title toastType={type}>{title}</Title>
        <Message>{message}</Message>
      </MessageWrapper>
    </Wrapper>
  );
};

export { Toast, StyledToastContainer };
