import styled from "styled-components";
import theme from "constants/theme";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageBtn = styled.button<{ active: boolean }>`
  border: none;
  outline: none;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${(props) =>
    props.active ? `${theme.color.orangeRed}` : "none"};
  color: ${(props) =>
    props.active ? `${theme.color.white} ` : `${theme.color.black}`};

  :disabled {
    opacity: 0.4;
  }
`;

export const PrevBtn = styled.button`
  display: flex;
  align-items: center;
  margin-right: 25px;
  border: none;
  outline: none;
  background: none;
  transform: rotate(-180deg);

  :disabled {
    opacity: 0.4;
  }
`;
export const NextBtn = styled.button`
  display: flex;
  align-items: center;
  margin-left: 25px;
  border: none;
  outline: none;
  background: none;

  :disabled {
    opacity: 0.4;
  }
`;

export const Img = styled.img`
  cursor: pointer;
`;
