import styled from "styled-components";
import theme from "constants/theme";

export const InputWrapper = styled.div`
  background-color: ${theme.color.backgroundGray};
  width: 100%;
  border-radius: 50px;
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  width: 100%;
  padding: 11px 24px;
  border: none;
  outline: none;
  background: none;
  color: ${theme.color.mainDark};

  &::placeholder {
    color: ${theme.color.mainLight};
  }
`;
