import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "constants/theme";

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and ${theme.device.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

export const Title = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 800;
  font-size: 48px;
  line-height: 65px;
  letter-spacing: 0.02em;
  color: ${theme.color.mainDark};

  @media screen and ${theme.device.tablet} {
    font-size: 40px;
    text-align: center;
  }
`;

export const CreateLink = styled(Link)`
  text-decoration: none;
  margin-bottom: 15px;
`;
