import styled from "styled-components";
import theme from "constants/theme";

export const Wallets = styled.div`
  font-size: 15px;
`;

export const Close = styled.div`
  cursor: pointer;
`;

export const Title = styled.div`
  margin: 0px 20px 20px 0px;
  color: ${theme.color.mainLight};
  font-weight: 500;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 0px 0px;
`;

export const Content = styled(Header)`
  align-items: center;
  margin: 0px;
`;

export const Wrapper = styled.div`
  background: ${theme.color.white};
  padding: 20px 35px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  a {
    color: ${theme.color.mainDark};
  }
`;
export const Provider = styled.a`
  padding: 10px 12px;
  border: 1px solid ${theme.color.borderGray};
  display: inline-block;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background: ${theme.color.backgroundGray};
    color: ${theme.color.mainDark};
  }
`;
