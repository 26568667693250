import styled from "styled-components";
import IconButton from "components/IconButton";
import theme from "constants/theme";
import { DisplayMode } from "./types";

export const BtnWrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
`;

export const CodeWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 60px;

  @media screen and ${theme.device.laptop} {
    flex-direction: column;
  }
`;

export const Code = styled.div<{ displayMode: string }>`
  padding: 32px;
  background-color: ${theme.color.backgroundGray};
  border-radius: 12px;
  max-height: 262px;
  width: ${({ displayMode }) => {
    switch (displayMode) {
      case DisplayMode.Text:
        return "50%";
      default:
        return "100%";
    }
  }};

  @media screen and ${theme.device.laptop} {
    width: 100%;
  }
`;

export const Label = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  white-space: pre;
  color: ${theme.color.mainLight};
`;

export const Overflow = styled.div`
  overflow: auto;
  max-height: 198px;
  height: 100%;
`;

export const TextBlock = styled.div`
  width: 50%;
  max-height: 262px;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(226, 226, 238, 0.48);
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.borderGray};
`;

export const StyledBtn = styled(IconButton)<{ color: string }>`
  transition: background-color 0.3s linear;

  color: ${({ color }) => (color === "red" ? `${theme.color.white}` : `${theme.color.black}`)};

  .icon {
    stroke: ${({ color }) => (color === "red" ? `${theme.color.white}` : `${theme.color.black}`)};
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  @media screen and ${theme.device.laptop} {
    flex-direction: column;
  }
`;
