/**
 * Returns shortened value
 *
 * @param {string} value
 *
 * @example
 * // return "jfqjf093...2347sdfssd"
 * shortenAddress(ipfs)
 *
 * @example
 * //return "0xB332a...c014"
 * shortenAddress(account)
 *
 * @returns {string} shortened value
 */

export const shortenAddress = (value: string) => {
  return value.length > 20 ? `${value.slice(0, 7)}...${value.slice(value.length - 4)}` : value;
};
