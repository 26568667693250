import React, { ReactNode } from "react";
import { StyledContainer } from "./style";

type Props = {
  children: ReactNode;
};

/**
 * Container. All content is wrapped in this component.
 *
 * @param children - any content inside container
 * @returns - TSX element (Container component)
 */

const Container: React.FC<Props> = ({ children }) => {
  return <StyledContainer>{children}</StyledContainer>;
};

export default Container;
