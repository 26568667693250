import styled from "styled-components";
import Pagination from "components/Pagination";
import Select from "components/Select";
import theme from "constants/theme";

export const Wrapper = styled.div`
  position: relative;
  min-height: 204px;
`;

export const ContractsContainer = styled.div<{ faded: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  opacity: ${({ faded }) => (faded ? "0.5" : 1)};
  transition: opacity ${({ faded }) => (faded ? "0s" : "0.5s")} linear;
  animation: ${theme.animation.fadeIn};

  @media screen and ${theme.device.laptop} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and ${theme.device.tablet} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledPagination = styled(Pagination)`
  margin: 26px 0 26px 0;
`;

export const Title = styled.p`
  font-weight: 800;
  font-size: 48px;
  line-height: 65px;
  letter-spacing: 0.02em;
  color: ${theme.color.mainDark};
  margin: 0 0 14px 0;
`;

export const SelectWrapper = styled.div`
  width: 360px;

  @media screen and ${theme.device.tablet} {
    width: 100%;
  }
`;

export const StyledSelect = styled(Select)`
  margin-bottom: 16px;

  .react-select__control {
    background-color: ${theme.color.white};
    border: 1px solid ${theme.color.borderGray};
  }
`;
