import { Link } from "react-router-dom";
import styled from "styled-components";
import GuardedButton from "components/GuardedButton";
import theme from "constants/theme";
import { ContractInfo } from "./components/ContractInfo";

export const DeployWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 19px;
  align-items: start;

  @media screen and ${theme.device.laptop} {
    flex-direction: column;
    width: 100%;
  }
`;

export const InputWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and ${theme.device.laptop} {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and ${theme.device.tablet} {
    align-items: center;
  }
`;

export const Case = styled.div`
  width: 100%;
  border: 1px solid ${theme.color.borderGray};
  border-radius: 12px;
  padding: 24px 32px;
  box-shadow: 0px 4px 8px rgba(226, 226, 238, 0.48);
  margin-bottom: 24px;
`;

export const BackBtn = styled(Link)`
  width: 48px;
  height: 48px;
  box-shadow: 0px 4px 8px rgba(226, 226, 238, 0.48);
  border: 1px solid ${theme.color.borderGray};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.5;
  }
`;

export const BackArrow = styled.img`
  transform: rotate(180deg);
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;

  @media screen and ${theme.device.tablet} {
    flex-direction: column;
  }
`;

export const Title = styled.span`
  font-weight: 800;
  font-size: 48px;
  line-height: 65px;
  letter-spacing: 0.02em;
  color: ${theme.color.mainDark};

  @media screen and ${theme.device.tablet} {
    font-size: 40px;
    text-align: center;
    line-height: 1.2em;
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  :not(:nth-last-child(1)) {
    margin-bottom: 12px;
  }

  @media screen and ${theme.device.tablet} {
    flex-direction: column;
  }
`;

export const Label = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${theme.color.mainDark};
`;

export const InputsWrapper = styled.div`
  width: 80%;

  @media screen and ${theme.device.tablet} {
    width: 100%;
  }
`;

export const StyledButton = styled(GuardedButton)`
  margin-bottom: 24px;
`;

export const StyledContractInfo = styled(ContractInfo)`
  width: 35%;

  @media screen and ${theme.device.laptop} {
    width: 100%;
  }
`;
