// External imports (third-party libraries)
import React from "react";
import { VFC, useMemo, useCallback } from "react";
import { AxiosError } from "axios";
import { useFormik, FormikConfig } from "formik";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams, useHistory } from "react-router";
import { api } from "api";
import Breadcrumbs from "components/Breadcrumbs";
import Container from "components/Container";
import Loader from "components/Loader";
import { PREV_NEXT_BTN } from "constants/images";
import { AppRoutes } from "constants/index";
import { useDeploy, useToast } from "hooks";

// Local styles
import {
  Wrapper,
  Case,
  Title,
  TitleWrapper,
  Item,
  Label,
  InputsWrapper,
  StyledButton,
  DeployWrapper,
  InputWrap,
  StyledContractInfo,
  BackBtn,
  BackArrow,
} from "./style";

// Local utils
import { prettifyAbiLabel } from "./utils";

// Local component
import ContractInput from "../Contract/components/ContractInput";

type ItemInputType = {
  label: string;
  placeholder: string;
};

type AbiInput = {
  name: string;
  type: string;
};

type FormValues = Record<string, string>;

/**
 * Contract Deploy page - the page on which you can implement the deployment
 * of the current contract. Сontains inputs generated from abi of
 * the current contract.
 *
 * @returns - TSX Element (Contract Deploy page)
 */

export const ContractDeploy: VFC = () => {
  const { t } = useTranslation();

  const deploy = useDeploy();

  const toast = useToast();

  const { contractId }: { contractId: string } = useParams();

  const history = useHistory();

  const { data, isFetching, isSuccess } = useQuery(
    ["contract-deploy", contractId],
    () => api.getContractById(Number(contractId)),
    {
      onError: (err: AxiosError) => {
        return toast.error({
          message: err.message,
          title: err.name,
        });
      },
    }
  );

  const fields = useMemo<ItemInputType[]>(() => {
    if (!isSuccess) {
      return [];
    }

    const json = data?.code
      ? JSON.parse(data?.code?.abi.replaceAll(/\\r|\\n|\\t/g, ""))
      : "";

    const { inputs }: { inputs: AbiInput[] } = json && json[0];

    return inputs?.map(({ name, type }) => ({
      label: name,
      placeholder: type,
    }));
  }, [isSuccess, data]);

  const handleSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values) => {
      try {
        await deploy(data!, values);
        history.push(`${AppRoutes.Contracts}/${contractId}`);

        toast.success({
          message: `${t("deploy.success.message")} [${
            data!.textContract?.ipfs
          }]`,
          title: `${t("deploy.success.title")}`,
        });
      } catch (error) {
        toast.error({
          message: `${error}`,
          title: `${t("deploy.error.title")}`,
        });
      }
    },
    [data, deploy, t, toast, history, contractId]
  );

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      _ipfs: data?.textContract?.ipfs || "",
      _token: "0x9fa4fe8a0b1e621847c729cd3a6d9da9f642728f",
    },
    onSubmit: handleSubmit,
  });

  return (
    <Container>
      <Breadcrumbs>{t("contractPage.title")}</Breadcrumbs>
      {isFetching && <Loader />}
      {isSuccess && (
        <Wrapper>
          <TitleWrapper>
            <BackBtn to={`${AppRoutes.Contracts}/${contractId}`}>
              <BackArrow alt="#" src={PREV_NEXT_BTN} />
            </BackBtn>
            <Title>{data!.name}</Title>
          </TitleWrapper>
          <DeployWrapper>
            <InputWrap>
              <Case>
                {fields?.map(({ label, placeholder }) => {
                  return (
                    <Item key={label}>
                      <Label>{prettifyAbiLabel(label)}</Label>
                      <InputsWrapper>
                        <ContractInput
                          name={label}
                          onChange={formik.handleChange}
                          placeholder={placeholder}
                          value={formik.values[label]}
                        />
                      </InputsWrapper>
                    </Item>
                  );
                })}
              </Case>
              <StyledButton
                color="gradient"
                onClick={formik.handleSubmit}
                type="submit"
              >
                {t("contractDeploy.deployBtn")}
              </StyledButton>
            </InputWrap>
            {data && <StyledContractInfo data={data} />}
          </DeployWrapper>
        </Wrapper>
      )}
    </Container>
  );
};
