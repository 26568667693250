import { FC } from "react";
import Footer from "components/Footer";
import Header from "components/Header";
import { Container } from "./style";

const AppLayout: FC = (props) => {
  const { children } = props;

  return (
    <Container>
      <Header />
      <main>{children}</main>
      <Footer />
    </Container>
  );
};

export default AppLayout;
