import styled from "styled-components";
import IconButton from "components/IconButton";
import theme from "constants/theme";

export const Wrapper = styled.div`
  border-radius: 12px;
  background-color: ${theme.color.backgroundGray};
  padding: 25px;
  margin: 16px 0 16px 0px;
`;

export const Label = styled.span`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  color: ${theme.color.mainLight};
`;

export const AddBtn = styled(IconButton)`
  margin: 0 10px 0 10px;
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
  }
`;
