import { Route, Switch } from "react-router";
import AppLayout from "components/AppLayout";
import ProviderModal from "components/ProviderModal";
import { AppRoutes } from "constants/index";
import { useAuth } from "hooks";
import { Catalog } from "pages/Catalog";
import { Contract } from "pages/Contract";
import { ContractCreate } from "pages/ContractCreate";
import { ContractDeploy } from "pages/ContractDeploy";
import { Home } from "pages/Home";
import { JunaSimple } from "pages/JunaSimple";

function App() {
  const { isProviderModalActive, toggleProviderModal } = useAuth();

  return (
    <>
      <AppLayout>
        <Switch>
          <Route component={Home} path={AppRoutes.Home} />
          <Route component={JunaSimple} path={AppRoutes.JunaSimple} />
          <Route component={Catalog} path={AppRoutes.Catalog} />
          <Route
            component={Contract}
            path={`${AppRoutes.Contracts}/:contractId`}
          />
          <Route
            component={ContractDeploy}
            path={`${AppRoutes.DeployContract}/:contractId`}
          />
          <Route
            component={ContractCreate}
            path={`${AppRoutes.ContractTemplate}/:contractId?`}
          />
          <Route component={Home} path="*" />
        </Switch>
      </AppLayout>
      <ProviderModal
        isVisible={isProviderModalActive}
        onClose={toggleProviderModal}
      />
    </>
  );
}

export default App;
