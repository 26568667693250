import styled from "styled-components";
import { MORE } from "constants/images";
import theme from "constants/theme";

export const Title = styled.div`
  font-weight: 800;
  font-size: 48px;
  line-height: 65px;
  letter-spacing: 0.02em;
  color: ${theme.color.mainDark};
  margin: 12px 0;

  @media screen and ${theme.device.tablet} {
    font-size: 32px;
    line-height: 44px;
  }
`;

export const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  width: 100%;
  animation: ${theme.animation.fadeIn};
  @media screen and ${theme.device.laptop} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and ${theme.device.tablet} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Wrapper = styled.div`
  position: relative;
  min-height: 332px;
  background: ${theme.color.backgroundGray};
  padding: 45px 0;
`;

export const More = styled.div`
  height: 48px;
  width: 109px;
  margin: 0 auto 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  background-image: url(${MORE});
  background-repeat: no-repeat;
`;

export const MoreTitle = styled.span`
  color: ${theme.color.mainDark};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin-top: -6px;
  margin-left: 1px;
  z-index: 1;
`;
