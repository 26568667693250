import { Fragment, VFC } from "react";
import { useTranslation } from "react-i18next";
import { PieChart } from "react-minimal-pie-chart";
import {
  Table,
  Wrapper,
  Line,
  TitleTable,
  TableWrap,
  AddressWrap,
  AdressColor,
  AdressTitle,
} from "pages/JunaSimple/style";
import type { PieChartData } from "pages/JunaSimple/types";

type Props = {
  chartData: PieChartData;
};

/**
 * ReadTable Page - сontains table with shareholders (and their shares),
 * And Pie chart
 *
 *
 * @returns - TSX Element (shareholders table)
 */

export const ReadTable: VFC<Props> = ({ chartData }) => {
  const { t } = useTranslation();
  return (
    <Table>
      <Wrapper>
        <TitleTable>{t("junaSimple.readPage.shareholders")}</TitleTable>
        {chartData.map(({ title, value, color }) => (
          <Fragment key={color}>
            <Line />
            <TableWrap key={value}>
              <AddressWrap>
                <AdressColor color={color} />
                <AdressTitle>{title}</AdressTitle>
              </AddressWrap>
              <div>{value}</div>
            </TableWrap>
          </Fragment>
        ))}
      </Wrapper>
      <PieChart animate center={[50, 35]} data={chartData} radius={35} />
    </Table>
  );
};
