import {
  TOAST_ERROR_ICON,
  TOAST_INFO_ICON,
  TOAST_SUCCESS_ICON,
} from "constants/images";
import theme from "constants/theme";

export enum ToastType {
  Error = "error",
  Info = "info",
  Success = "success",
}

export const ToastIcon = {
  [ToastType.Error]: TOAST_ERROR_ICON,
  [ToastType.Info]: TOAST_INFO_ICON,
  [ToastType.Success]: TOAST_SUCCESS_ICON,
} as const;

export const ToastColor = {
  [ToastType.Error]: theme.color.red,
  [ToastType.Info]: theme.color.blue,
  [ToastType.Success]: theme.color.green,
} as const;
