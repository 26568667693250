import { VFC } from "react";
import { Wrapper, Step, Label, Description, Img } from "./style";

type StepItem = {
  label: string;
  description: string;
  src: string;
};

type Props = {
  steps: StepItem[];
};

/**
 * Steps - section from home page with appropriate markup
 *
 * @param steps - array with data to render
 * @returns - TSX Element (Steps component)
 */

const Steps: VFC<Props> = (props) => {
  const { steps } = props;

  return (
    <Wrapper>
      {steps.map(({ label, description, src }) => (
        <Step key={label}>
          <Img key={label} alt="#" src={src} />
          <Label>{label}</Label>
          <Description>{description}</Description>
        </Step>
      ))}
    </Wrapper>
  );
};

export default Steps;
