import { Link } from "react-router-dom";
import styled from "styled-components";
import ConnectButton from "components/ConnectButton";
import theme from "constants/theme";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: ${theme.color.white};
  margin-top: 1px;
  padding-bottom: 32px;
`;

export const Ul = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
`;

export const Li = styled.li`
  list-style: none;

  :not(:nth-last-child(1)) {
    margin-bottom: 100px;
  }

  @media screen and ${theme.device.tablet} {
    :not(:nth-last-child(1)) {
      margin-bottom: 36px;
    }
  }
`;

export const StyledLink = styled(Link)`
  font-size: 40px;
  color: ${theme.color.mainLight};
  text-decoration: none;
  font-weight: 600;

  @media screen and ${theme.device.tablet} {
    font-size: 20px;
  }
`;

export const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: auto;
`;

export const StyledButton = styled(ConnectButton)`
  margin: 0 auto;
  width: 90%;
  color: ${theme.color.mainDark};

  @media screen and ${theme.device.laptop} {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and ${theme.device.tablet} {
    width: 65%;
  }
`;
