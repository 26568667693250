import { FC } from "react";
import { Wrapper } from "./style";
import { IconBtnColor } from "./types";

type Props = {
  className?: string;
  color: IconBtnColor;
  onClick?: () => void;
};

/**
 * IconButton are a ui-kit element,the same functionality as the Button component, only for using buttons from icons
 *
 * @param color - color of the button
 * @param onClick - onClick function
 * @param className - className for styled-components
 * @param children - in children we accept the button text
 *
 * @returns - TSX element (Icon Button component)
 */

const IconButton: FC<Props> = (props) => {
  const { children, color, className, onClick } = props;

  return (
    <Wrapper className={className} color={color} onClick={onClick}>
      {children}
    </Wrapper>
  );
};

export default IconButton;
