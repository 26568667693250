import { VFC, useState, useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Container from "components/Container/index";
import { LOGO } from "constants/images";
import { AppRoutes } from "constants/index";
import { AuthContext } from "context";
import {
  GlobalStyle,
  HeaderWrapper,
  Wrapper,
  StyledMobileMenu,
  Ul,
  Li,
  StyledLink,
  Hamburger,
  StyledConnectButton,
  Logo,
} from "./style";

/**
 * Header stores navigation links, and a button for connecting wallets
 *
 * @returns - TSX element (Header component)
 */

const Header: VFC = () => {
  const { toggleProviderModal } = useContext(AuthContext);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const history = useHistory();

  const { pathname } = useLocation();

  const isHomePage = pathname === AppRoutes.Home;

  const { t } = useTranslation();

  const toggleMenu = useCallback(
    () => setIsMenuOpen((prev) => !prev),
    [setIsMenuOpen]
  );

  const handleLogoClick = useCallback(
    () => !isHomePage && history.push(AppRoutes.Home),
    [isHomePage, history]
  );

  const navLinks = useMemo(
    () => [
      {
        label: t("header.nav.junaSimple"),
        to: AppRoutes.JunaSimple,
      },
      {
        label: t("header.nav.catalog"),
        to: AppRoutes.Catalog,
      },
      {
        label: t("header.nav.template"),
        to: AppRoutes.ContractTemplate,
      },
    ],
    [t]
  );

  return (
    <HeaderWrapper>
      <GlobalStyle isMenuOpen={isMenuOpen} />
      <Container>
        <Wrapper>
          <Logo
            alt="#"
            isHome={isHomePage}
            onClick={handleLogoClick}
            src={LOGO}
          />
          <Ul>
            {navLinks.map(({ label, to }) => (
              <Li key={to}>
                <StyledLink to={to}>{label}</StyledLink>
              </Li>
            ))}
          </Ul>
          <StyledConnectButton onClick={toggleProviderModal} />
          <Hamburger isMenuOpen={isMenuOpen} onClick={toggleMenu} />
          {isMenuOpen && (
            <StyledMobileMenu
              navLinks={navLinks}
              onOpenProviderModal={toggleProviderModal}
              toggleMenu={toggleMenu}
            />
          )}
        </Wrapper>
      </Container>
    </HeaderWrapper>
  );
};

export default Header;
