import React, { useCallback, useMemo } from "react";
import { PREV_NEXT_BTN } from "constants/images";
import theme from "constants/theme";
import { useWindowDimensions } from "hooks";
import { Wrapper, PageBtn, PrevBtn, NextBtn, Img } from "./style";

const PagesToShowAmount = {
  DEFAULT: 9,
  MOBILE: 4,
};

const getPagesRange = (
  currentPage: number,
  totalPages: number,
  pagesToShow: number
): number[] => {
  const halfRange = Math.ceil((pagesToShow - 1) / 2);
  const allPages = Array.from({ length: totalPages }, (_, idx) => idx);

  let from = currentPage - halfRange;
  let to = currentPage + halfRange + (pagesToShow % 2);

  if (from < 0) {
    from = 0;
    to = pagesToShow;
  }

  if (to > totalPages) {
    from = totalPages - pagesToShow;
    to = totalPages;
  }

  return allPages.slice(from, to);
};

type Props = {
  onChangePage: (page: number) => void;
  currentPage: number;
  totalPages: number;
  className?: string;
  disabled?: boolean;
};

/**
 * The pagination component in which the logic of switching between existing pages
 * is implemented using buttons or arrows on the sides.
 *
 * @param currentPage - we receive the number of the current page
 * @param totalPages - an array containing all pages
 * @param onChangePage - function triggered by clicking on an item from pagination
 *
 * @returns - TSX element (Pagination component)
 */

const Pagination: React.FC<Props> = (props): JSX.Element => {
  const { currentPage, totalPages, onChangePage, className, disabled } = props;

  const { width } = useWindowDimensions();

  const pagesToShow = useMemo(
    (): number =>
      width > theme.size.tablet
        ? PagesToShowAmount.DEFAULT
        : PagesToShowAmount.MOBILE,
    [width]
  );

  const pagesRange = useMemo(
    () => getPagesRange(currentPage, totalPages, pagesToShow),
    [currentPage, totalPages, pagesToShow]
  );

  const moveToPrevPage = useCallback(() => {
    onChangePage(currentPage - 1);
  }, [currentPage, onChangePage]);

  const moveToNextPage = useCallback(() => {
    onChangePage(currentPage + 1);
  }, [currentPage, onChangePage]);

  const moveToPage = useCallback(
    (pageNum) => {
      onChangePage(pageNum);
    },
    [onChangePage]
  );

  return (
    <Wrapper className={className}>
      <PrevBtn
        disabled={currentPage === 1 || disabled}
        onClick={moveToPrevPage}
      >
        <Img alt="#" src={PREV_NEXT_BTN} />
      </PrevBtn>
      {pagesRange.map((pageNum: number) => {
        const isActive = currentPage === pageNum + 1;
        return (
          <PageBtn
            key={pageNum}
            active={isActive}
            disabled={disabled}
            onClick={() => moveToPage(pageNum + 1)}
          >
            {pageNum + 1}
          </PageBtn>
        );
      })}
      <NextBtn
        disabled={currentPage === totalPages || disabled}
        onClick={moveToNextPage}
      >
        <Img alt="#" src={PREV_NEXT_BTN} />
      </NextBtn>
    </Wrapper>
  );
};

export default Pagination;
