import { VFC } from "react";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { api } from "api";
import Breadcrumbs from "components/Breadcrumbs";
import Button from "components/Button";
import Container from "components/Container";
import Loader from "components/Loader";
import { AppRoutes } from "constants/index";
import { useToast } from "hooks";
import ContractCode from "./components/ContractCode";
import ContractInfo from "./components/ContractInfo";
import { FlexWrapper, Title, CreateLink } from "./style";

/**
 * Contract Page - contains a block with information about the selected
 * contract, the ability to view the smart contract code, and also to deploy
 *
 * @returns - TSX Element (Contract page)
 */

export const Contract: VFC = () => {
  const { t } = useTranslation();

  const toast = useToast();

  const { contractId }: { contractId: string } = useParams();

  const { data, isFetching, isSuccess } = useQuery(
    ["contract", contractId],
    () => api.getContractById(Number(contractId)),
    {
      onError: (err: AxiosError) => {
        return toast.error({
          message: err.message,
          title: err.name,
        });
      },
    }
  );

  return (
    <Container>
      <Breadcrumbs>{t("contractPage.title")}</Breadcrumbs>
      <FlexWrapper>
        <Title>{t("contractPage.title")}</Title>
        <CreateLink to={`${AppRoutes.DeployContract}/${contractId}`}>
          <Button color="gradient">{t("contractPage.createBtn")}</Button>
        </CreateLink>
      </FlexWrapper>
      {isFetching && <Loader />}
      {isSuccess && (
        <>
          <ContractInfo
            audit={data?.code?.audit}
            contractId={data!.id}
            description={data!.description}
            ipfs={data?.textContract?.ipfs}
            jurisdicrion={data!.jurisdiction.name}
            name={data!.name}
            owner={data!.owner}
          />
          <ContractCode
            abi={data?.code?.abi}
            code={data?.code?.code}
            versions={data?.textContract?.versions}
          />
        </>
      )}
    </Container>
  );
};
