import { FC } from "react";
import { Wrapper } from "./style";
import { ButtonColor } from "./types";

export type Props = {
  className?: string;
  color?: ButtonColor;
  onClick?: () => void;
  type?: "submit" | "reset" | "button";
};

/**
 * Buttons are a ui-kit element, this component can be used many times by changing its appearance and functionality with props.
 *
 * @param className - className for styled-components
 * @param color - color of the button
 * @param type - button type as html attribute
 * @param onClick - onClick function
 * @param children - in children we accept the button text
 *
 * @returns - TSX element (Button component)
 */

const Button: FC<Props> = (props) => {
  const { type, children, className, color = "white", onClick } = props;

  return (
    <Wrapper className={className} color={color} onClick={onClick} type={type}>
      {children}
    </Wrapper>
  );
};

export default Button;
