import { memo, VFC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Container from "components/Container/index";
import { TWITTER_ICON, TELEGRAM_ICON, DISCORD_ICON } from "constants/images";
import { AppRoutes } from "constants/index";
import {
  Wrapper,
  FooterWrapper,
  LeftSide,
  NavLink,
  LinkWrapper,
  LinkItem,
  RightSide,
  Img,
} from "./style";

/**
 * Footer component, which stores various links to certain tabs / services
 *
 * @returns - TSX element (Footer component)
 */

const Footer: VFC = () => {
  const { t } = useTranslation();

  const navLinks = useMemo(
    () => [
      {
        label: t("footer.nav.catalog"),
        to: AppRoutes.Catalog,
      },
      {
        label: t("footer.nav.template"),
        to: AppRoutes.ContractTemplate,
      },
      {
        label: t("footer.nav.junaSimple"),
        to: AppRoutes.JunaSimple,
      },
    ],
    [t]
  );

  const socialLinks = useMemo(
    () => [
      {
        src: TWITTER_ICON,
        label: t("footer.links.twitter"),
        href: "",
      },
      {
        src: TELEGRAM_ICON,
        label: t("footer.links.telegram"),
        href: "",
      },
      {
        src: DISCORD_ICON,
        label: t("footer.links.discord"),
        href: "",
      },
    ],
    [t]
  );

  return (
    <FooterWrapper>
      <Container>
        <Wrapper>
          <LeftSide>
            {navLinks.map(({ label, to }) => (
              <NavLink key={to} to={to}>
                {label}
              </NavLink>
            ))}
          </LeftSide>
          <RightSide>
            {socialLinks.map(({ src, label, href }) => (
              <LinkWrapper key={label}>
                <Img alt="#" src={src} />
                <LinkItem href={href} target="_blank">
                  {label}
                </LinkItem>
              </LinkWrapper>
            ))}
          </RightSide>
        </Wrapper>
      </Container>
    </FooterWrapper>
  );
};

export default memo(Footer);
