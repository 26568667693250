import styled from "styled-components";
import IconButton from "components/IconButton";
import theme from "constants/theme";

export const Wrapper = styled.div`
  padding: 24px 32px;
  box-shadow: 0px 4px 8px rgba(226, 226, 238, 0.48);
  border-radius: 12px;
  border: 1px solid ${theme.color.borderGray};
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and ${theme.device.tablet} {
    &:not(:first-child) {
      flex-direction: column;
    }
  }
`;

export const Label = styled.span`
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: ${theme.color.mainDark};
`;

export const Overflow = styled.div`
  height: 100%;
  overflow: auto;
`;

export const ShareholderLabel = styled.span`
  width: 50%;
  height: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin: 12px 0 12px 0;
  color: ${theme.color.mainDark};
  border: none;
  outline: none;
  background: none;
  resize: none;

  &:nth-child(2) {
    width: 50%;
    text-align: right;
  }

  @media screen and ${theme.device.tablet} {
    width: 100%;
    text-align: center;
    margin: 0;
    margin-bottom: 5px;
    overflow-wrap: break-word;
    height: auto;

    &:nth-child(2) {
      width: 100%;
      text-align: center;
    }
  }
`;

export const Line = styled.div`
  width: 100%;
  background-color: ${theme.color.borderGray};
  height: 1px;

  &:nth-child(1) {
    margin-top: 16px;
  }

  @media screen and ${theme.device.tablet} {
    margin-bottom: 15px;
  }
`;

export const ShareholderInput = styled.input`
  border: none;
  outline: none;
  width: 80%;
  margin-top: 12px;
  padding: 10px 24px;
  border-radius: 50px;
  background-color: ${theme.color.backgroundGray};
  color: ${theme.color.mainLight};

  &::placeholder {
    color: ${theme.color.mainLight};
  }

  &:nth-last-child(1) {
    width: 19%;
  }

  @media screen and ${theme.device.tablet} {
    width: 100%;
    border-radius: 25px;

    &:nth-last-child(1) {
      width: 100%;
    }
  }
`;

export const StyledEditBtn = styled(IconButton)`
  transition: 0.3s;

  &:hover {
    opacity: 0.5;
  }
`;

export const RemoveBtn = styled.button`
  margin-left: 15px;
  border: none;
  outline: none;
  padding: 5px 20px;
  border-radius: 15px;
  color: ${theme.color.orangeRed};
  border: 1px solid ${theme.color.borderGray};
  background-color: #fff;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  @media screen and ${theme.device.tablet} {
    margin-left: 0;
    margin-bottom: 15px;
  }
`;
