import styled from "styled-components";
import theme from "constants/theme";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 157px;
  align-items: end;

  @media screen and ${theme.device.laptop} {
    gap: 80px;
  }
  @media screen and ${theme.device.tablet} {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 118px;

  @media screen and ${theme.device.tablet} {
    margin-bottom: 60px;
  }
`;

export const Circle = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: ${theme.color.borderGray};
  margin: 0 auto;
`;

export const Label = styled.p`
  margin: 32px 0 8px 0;
  padding: 0;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: ${theme.color.mainDark};
  text-align: center;
`;

export const Description = styled.p`
  margin: 0 auto;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: ${theme.color.mainLight};
  max-width: 240px;
  text-align: center;
`;

export const Img = styled.img``;
