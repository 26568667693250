import { FC, createContext, useCallback, useState } from "react";
import { App } from "typings";

const AuthContext = createContext({} as App.AuthContext);

const AuthProvider: FC = (props) => {
  const { children } = props;

  const [isProviderModalActive, setIsProviderModalActive] =
    useState<boolean>(false);

  const toggleProviderModal = useCallback(
    () => setIsProviderModalActive((prev) => !prev),
    []
  );

  return (
    <AuthContext.Provider
      value={{
        isProviderModalActive,
        toggleProviderModal,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
