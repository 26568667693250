import styled from "styled-components";

export const Wrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoImg = styled.img`
  position: relative;
  z-index: 1;
`;

export const SvgBars = styled.svg`
  opacity: 0.3;
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translateY(-50%);
  z-index: 0;
`;
