import { VFC, memo } from "react";
import { Wrapper, Ul, Li, StyledLink, BtnWrapper, StyledButton } from "./style";

type NavLink = {
  label: string;
  to: string;
};

type Props = {
  onOpenProviderModal: () => void;
  toggleMenu: () => void;
  className?: string;
  navLinks: NavLink[];
};

/**
 * Menu component that opens by clicking on a button that appears on tablet and mobile devices and
 * contains elements from the header in the computer version
 *
 * @param onOpenProviderModal - function for the correct operation of the connect button
 * @param toggleMenu - the function is used to close the menu by clicking on any nav link
 * @param className - className for styled-components
 * @returns - TSX element (Mobile Menu component)
 */

const MobileMenu: VFC<Props> = (props) => {
  const { onOpenProviderModal, toggleMenu, className, navLinks } = props;

  return (
    <Wrapper className={className}>
      <Ul>
        {navLinks.map(({ label, to }) => (
          <Li key={to} onClick={toggleMenu}>
            <StyledLink to={to}>{label}</StyledLink>
          </Li>
        ))}
      </Ul>
      <BtnWrapper>
        <StyledButton onClick={onOpenProviderModal} />
      </BtnWrapper>
    </Wrapper>
  );
};

export default memo(MobileMenu);
