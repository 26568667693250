import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { Title, Wrapper, Img } from "./style";

type Product = {
  id: number;
  src: string;
};

type Props = {
  products: Product[];
};

/**
 * Products - section from home page with appropriate markup
 *
 * @param products - array with data to render
 * @returns - TSX Element (Products component)
 */

const Products: VFC<Props> = (props) => {
  const { t } = useTranslation();

  const { products } = props;

  return (
    <>
      <Title>{t("mainPage.products.title")}</Title>
      <Wrapper>
        {products.map(({ id, src }) => (
          <Img key={id} alt="#" src={src} />
        ))}
      </Wrapper>
    </>
  );
};

export default Products;
