import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "constants/theme";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and ${theme.device.tablet} {
    flex-direction: column;
  }
`;
export const FooterWrapper = styled.div`
  background: ${theme.color.backgroundGray};
  padding: 25px 0px 20px 0px;
  bottom: 0;
  margin-top: auto;
`;
export const LeftSide = styled.div`
  display: flex;
  align-items: center;

  @media screen and ${theme.device.tablet} {
    margin-bottom: 25px;
    flex-direction: column;
  }
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  color: ${theme.color.mainLight};
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;

  :not(:nth-last-child(1)) {
    margin-right: 22px;
  }

  @media screen and ${theme.device.tablet} {
    :not(:nth-last-child(1)) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  margin-right: 22px;
  align-items: center;

  :nth-child(3) {
    margin-right: 0;
  }
  @media screen and ${theme.device.tablet} {
    margin: 0 auto 15px auto;

    :nth-child(3) {
      margin-right: 5px;
      margin-bottom: 0px;
    }
  }
`;
export const LinkItem = styled.a`
  text-decoration: none;
  color: ${theme.color.mainLight};
  font-size: 14px;
  font-weight: 600;
`;
export const RightSide = styled.div`
  display: flex;

  @media screen and ${theme.device.tablet} {
    flex-direction: column;
  }
`;
export const Img = styled.img`
  margin-right: 8px;
`;
