import React, { useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import { WrapModal } from "./style";

type ModalProps = {
  onClose: () => void;
  isVisible: boolean;
};

const PORTAL_TARGET = "portal";

/**
 * Modal -This component describes the logic of the modal window
 *
 * @param onClose - function to close the modal window
 * @param isVisible - boolean about an open or closed modal window
 *
 * @returns - TSX and DOM elements (Modal component, domElement)
 */

export const Modal: React.FC<ModalProps> = (props) => {
  const { onClose, children, isVisible } = props;

  const domElement = document.getElementById(PORTAL_TARGET);

  const outsideRef = React.useRef(null);

  const handleWrapperClick = (
    evt: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (evt.target === outsideRef.current) {
      onClose();
    }
  };

  const handleKeyDown = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === "Escape") {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  if (!domElement || !isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <WrapModal ref={outsideRef} onClick={handleWrapperClick}>
      {children}
    </WrapModal>,
    domElement
  );
};
