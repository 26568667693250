import styled from "styled-components";
import theme from "constants/theme";

export const IpfsInput = styled.input`
  box-shadow: 0px 4px 8px rgba(226, 226, 238, 0.48);
  border-radius: 12px;
  padding: 13px 32px;
  border: 1px solid ${theme.color.borderGray};
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  outline: none;
  color: ${theme.color.mainLight};
  width: 60%;

  &::placeholder {
    color: ${theme.color.mainLight};
  }

  @media screen and ${theme.device.tablet} {
    width: 100%;
    margin-bottom: 15px;
  }
`;

export const FlexWrapper = styled.div`
  margin-top: 29px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and ${theme.device.tablet} {
    flex-direction: column;
  }
`;

export const Name = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${theme.color.mainDark};
  margin-right: 9px;
`;
