import { VFC, useState } from "react";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { api } from "api";
import Breadcrumbs from "components/Breadcrumbs";
import Container from "components/Container";
import ContractCard from "components/ContractCard";
import Loader from "components/Loader";
import { useToast } from "hooks";
import { Api } from "typings";
import {
  ContractsContainer,
  Wrapper,
  StyledPagination,
  Title,
  SelectWrapper,
  StyledSelect,
} from "./style";

const CONTRACTS_AMOUNT = 8;

/**
 * Catalog Page - сontains information about existing smart contracts,
 * with the ability to sort by category
 *
 * @returns - TSX Element (Catalog page)
 */

export const Catalog: VFC = () => {
  const { t } = useTranslation();

  const toast = useToast();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [currentCategory, setCurrentCategory] = useState<Api.Category>();

  const { data, isFetching, isSuccess } = useQuery(
    ["contracts", currentPage, currentCategory],
    () => {
      return api.getContracts({
        page: currentPage,
        size: CONTRACTS_AMOUNT,
        category: currentCategory?.id,
      });
    },
    {
      keepPreviousData: true,
      onError: (err: AxiosError) => {
        return toast.error({
          message: err.message,
          title: err.name,
        });
      },
    }
  );

  const { data: categories, isLoading: isCategoriesLoading } = useQuery(
    ["categories"],
    api.getCategories
  );

  const handleCategoryChange = (category: any) =>
    setCurrentCategory(category as Api.Category);

  return (
    <>
      <Container>
        <Breadcrumbs>{t("contractTemplate.title")}</Breadcrumbs>
        <Title>{t("contractTemplate.title")}</Title>
        <SelectWrapper>
          <StyledSelect
            getOptionLabel={(option) => (option as Api.Category).name}
            getOptionValue={(option) => `${(option as Api.Category).id}`}
            isDisabled={isCategoriesLoading}
            isLoading={isCategoriesLoading}
            onChange={handleCategoryChange}
            options={categories}
            placeholder={t("select.category")}
            value={currentCategory}
          />
        </SelectWrapper>
        <Wrapper>
          {isFetching && <Loader />}
          {isSuccess && (
            <ContractsContainer faded={isFetching}>
              {data!.results.map((contract) => (
                <ContractCard
                  key={contract.id}
                  country={contract.jurisdiction.name}
                  id={contract.id}
                  title={contract.name}
                />
              ))}
            </ContractsContainer>
          )}
        </Wrapper>
        {data && data.totalPages > 1 && (
          <StyledPagination
            currentPage={currentPage}
            disabled={isFetching}
            onChangePage={(page) => setCurrentPage(page)}
            totalPages={data.totalPages}
          />
        )}
      </Container>
    </>
  );
};
