import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "components/Modal";
import {
  Wallets,
  Close,
  Title,
  Header,
  Content,
  Wrapper,
  Provider,
} from "components/ProviderModal/style";
import {
  METAMASK_ICON,
  CLOSE_ICON,
  BSCWALLET_ICON,
  WALLETCONNECT_ICON,
  TRUSTWALLET_ICON,
  MATHWALLET_ICON,
} from "constants/images";
import { useAuth } from "hooks";

const wallets = [
  {
    name: "Metamask",
    icon: METAMASK_ICON,
  },
  {
    name: "Binance Smart Chain",
    icon: BSCWALLET_ICON,
  },
  {
    name: "Wallet Connect",
    icon: WALLETCONNECT_ICON,
  },
  {
    name: "Trust wallet",
    icon: TRUSTWALLET_ICON,
  },
  {
    name: "Math Wallet",
    icon: MATHWALLET_ICON,
  },
];

type Props = {
  isVisible: boolean;
  onClose: () => void;
};

/**
 * Provider Modal - Modal window for connecting wallets
 *
 * @param onClose - function to close the provider modal window
 * @param isVisible - boolean about an open or closed provider modal
 *
 * @returns - TSX Element (Provider Modal component)
 */

const ProviderModal: VFC<Props> = (props) => {
  const { isVisible, onClose } = props;

  const { t } = useTranslation();

  const { logIn } = useAuth();

  const handleWalletClick = async () => {
    await logIn();
    onClose();
  };

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <Wrapper>
        <Header>
          <Title>{t("modal.title")}</Title>
          <Close onClick={onClose}>
            <img alt="#" src={CLOSE_ICON} />
          </Close>
        </Header>

        {wallets.map((wallets) => (
          <Provider key={wallets.name} onClick={handleWalletClick}>
            <Content>
              <Wallets>{wallets.name}</Wallets>
              <img alt="#" src={wallets.icon} />
            </Content>
          </Provider>
        ))}
      </Wrapper>
    </Modal>
  );
};

export default ProviderModal;
