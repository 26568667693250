/**
 * This function converts incoming abi labels of fields into human readable
 *
 * @param {string} label - the string to be converted
 * @returns {string} - converted string
 *
 * @example
 * // returns "Buyer"
 * prettifyAbiLabel("_buyer");
 * @example
 * // returns "Supplier"
 * prettifyAbiLabel("_supplier");
 * @example
 * // returns "Legal oracle"
 * prettifyAbiLabel("_legalOracle");
 */

export const prettifyAbiLabel = (label: string): string => {
  const convertedLabel = label
    .replace(/^_*/, "")
    .split(/\s+/)
    .join(" ")
    .replace(/[A-Z]/g, (str) => ` ${str.toLowerCase()}`);

  return convertedLabel[0].toUpperCase() + convertedLabel.substring(1);
};
