import ReactSelect, { Props as SelectProps, GroupBase } from "react-select";
import { colourStyles } from "./style";

/**
 * Select - a component that uses select from the React-Select library.
 *
 * @param className - className for styled-components
 * @param placeholder - placeholder for select
 * @param onChange - onChange select function
 * @param selectOptions - an array of storage objects containing the options of the current select
 * @param value - select value
 *
 * @returns - TSX element (Select component)
 */

function Select<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: SelectProps<Option, IsMulti, Group>) {
  return (
    <ReactSelect
      classNamePrefix="react-select"
      styles={colourStyles}
      {...props}
    />
  );
}

export default Select;
