import { Link } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import ConnectButton from "components/ConnectButton";
import { HAMBURGER_ICON, HAMBURGER_CLOSE_ICON } from "constants/images";
import theme from "constants/theme";
import MobileMenu from "./MobileMenu/index";

const HEIGHT_DESKTOP = "80px";
const HEIGHT_MOBILE = "60px";

export const GlobalStyle = createGlobalStyle<{ isMenuOpen: boolean }>`
    body {
        overflow: ${({ isMenuOpen }) => (isMenuOpen ? "hidden" : "")};
    }
`;

export const HeaderWrapper = styled.header`
  width: 100%;
  border-bottom: 1px solid ${theme.color.borderGray};
`;

export const Wrapper = styled.div`
  display: flex;
  height: ${HEIGHT_DESKTOP};
  align-items: center;
  justify-content: space-between;

  @media screen and ${theme.device.tablet} {
    height: ${HEIGHT_MOBILE};
  }
`;

export const StyledMobileMenu = styled(MobileMenu)`
  position: absolute;
  top: ${HEIGHT_DESKTOP};
  left: 0;
  width: 100%;
  height: calc(100vh - ${HEIGHT_DESKTOP});
  z-index: 10;

  @media screen and ${theme.device.tablet} {
    top: ${HEIGHT_MOBILE};
    height: calc(100vh - ${HEIGHT_MOBILE});
  }
`;

export const Ul = styled.ul`
  display: flex;
  gap: 50px;

  @media screen and ${theme.device.laptop} {
    display: none;
  }
`;

export const Li = styled.li`
  list-style: none;
`;

export const StyledLink = styled(Link)`
  font-size: 16px;
  color: ${theme.color.mainLight};
  text-decoration: none;
  font-weight: 600;
`;

export const Hamburger = styled.button<{ isMenuOpen: boolean }>`
  background: url(${({ isMenuOpen }) =>
    isMenuOpen ? HAMBURGER_CLOSE_ICON : HAMBURGER_ICON});
  width: 24px;
  height: 24px;
  border: none;
  outline: none;
  display: none;
  cursor: pointer;

  @media screen and ${theme.device.laptop} {
    display: block;
  }
`;

export const StyledConnectButton = styled(ConnectButton)`
  @media screen and ${theme.device.laptop} {
    display: none;
  }
`;

export const Logo = styled.img<{ isHome: boolean }>`
  cursor: ${({ isHome }) => (isHome ? "default" : "pointer")};

  @media screen and ${theme.device.laptop} {
    width: 127px;
  }
`;
