import { ComponentProps } from "react";
import { PieChart } from "react-minimal-pie-chart";

export enum DisplayMode {
  Deploy = "DEPLOY",
  Read = "READ",
}

export type SelectOption = {
  value: DisplayMode;
  label: string;
};

export type PieChartData = ComponentProps<typeof PieChart>["data"];
