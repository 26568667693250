import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { ContractFactory, ethers } from "ethers";
import { Api } from "typings";

const getArrayByAbi = (abi: any, args: any) => {
  return abi[0].inputs.map((entry: any) => {
    switch (entry.type) {
      case "address":
        return args[entry.name].toString();
      case "string":
        return args[entry.name].toString();
      default:
        return args[entry.name];
    }
  });
};

export const useDeploy = () => {
  const web3 = useWeb3React();

  return useCallback(
    async (
      contract: Api.Contract,
      args: Record<string, string | string[] | number[]>
    ) => {
      const provider = new ethers.providers.Web3Provider(web3.library.provider);
      const abi =
        contract?.code &&
        JSON.parse(contract.code.abi.replaceAll(/\\r|\\n|\\t/g, ""));
      const contractFactory =
        contract?.code &&
        new ContractFactory(abi, contract.code.bytecode, provider.getSigner(0));
      return (
        contractFactory &&
        (await contractFactory.deploy(...getArrayByAbi(abi, args)))
      );
    },
    [web3.library]
  );
};
