import { VFC, useState, useMemo } from "react";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { OnChangeValue } from "react-select";
import { api } from "api";
import Breadcrumbs from "components/Breadcrumbs";
import Container from "components/Container";
import Loader from "components/Loader";
import { useToast } from "hooks";
import Deploy from "./features/Deploy";
import Read from "./features/Read";
import {
  LabelWrapper,
  Title,
  Jurisdiction,
  FlexWrapper,
  SelectWrapper,
  StyledSelect,
} from "./style";
import { DisplayMode, SelectOption } from "./types";

const CONTRACT_ID = 15;

export const JunaSimple: VFC = () => {
  const [displayMode, setDisplayMode] = useState<DisplayMode>(
    DisplayMode.Deploy
  );

  const toast = useToast();

  const { t } = useTranslation();

  const { data, isFetching, isSuccess } = useQuery(
    ["juna-simple-contract", CONTRACT_ID],
    () => api.getContractById(CONTRACT_ID),
    {
      onError: (err: AxiosError) => {
        return toast.error({
          message: err.message,
          title: err.name,
        });
      },
    }
  );

  const selectOptions: SelectOption[] = useMemo(
    () => [
      { value: DisplayMode.Deploy, label: t("junaSimple.deploy") },
      { value: DisplayMode.Read, label: t("junaSimple.read") },
    ],
    [t]
  );

  const handleSelect = (selection: OnChangeValue<SelectOption, false>) =>
    setDisplayMode(selection!.value);

  return (
    <Container>
      <Breadcrumbs>{t("junaSimple.title")}</Breadcrumbs>
      {isFetching && <Loader />}
      {isSuccess && (
        <>
          <FlexWrapper>
            <LabelWrapper>
              <Title>{data!.name}</Title>
              {displayMode === DisplayMode.Deploy && (
                <Jurisdiction>{data!.jurisdiction.name}</Jurisdiction>
              )}
            </LabelWrapper>
            <SelectWrapper>
              <StyledSelect
                onChange={handleSelect}
                options={selectOptions}
                placeholder={t("select.main")}
                value={selectOptions.filter(
                  ({ value }) => value === displayMode
                )}
              />
            </SelectWrapper>
          </FlexWrapper>
          {displayMode === DisplayMode.Deploy && <Deploy contract={data!} />}
          {displayMode === DisplayMode.Read && <Read contract={data!} />}
        </>
      )}
    </Container>
  );
};
