import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { ToastType, ToastColor } from "./constants";

export const StyledToastContainer = styled(ToastContainer)`
  padding: 0;

  .Toastify__toast {
    border-radius: 12px;
    padding: 0 5px 0 0;
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__close-button {
    position: relative;
    top: 8px;
    right: 2px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin: 0;
  padding: 0;
`;

export const Status = styled.div<{ toastType: `${ToastType}` }>`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  background-color: ${({ toastType }) => ToastColor[toastType]};
  border-radius: 12px 0 0 12px;
`;

export const Img = styled.img`
  padding: 22.4px 12px;
  margin: auto;
`;

export const Title = styled.div<{ toastType: `${ToastType}` }>`
  font-size: 16px;
  padding: 5px 0 0 0;
  font-weight: 700;
  color: ${({ toastType }) => ToastColor[toastType]};
`;

export const Message = styled.div`
  font-size: 14px;
  margin: 0 0 5px 0;
  overflow-wrap: break-word;
`;

export const MessageWrapper = styled.div`
  margin-left: 60px;
  max-width: 225px;
`;
