import { useMemo, VFC } from "react";
import { Api } from "typings";
import { Info, Value, Wrapper } from "./style";

type Props = {
  data: Api.Contract;
  className?: string;
};

export const ContractInfo: VFC<Props> = (props) => {
  const { data, className } = props;

  const info = useMemo(
    () => [
      {
        title: "Contract name",
        value: data.name,
      },
      {
        title: "Description",
        value: data.description,
      },
      {
        title: "ipfs link",
        value: data?.textContract?.ipfs,
      },
      {
        title: "Jurisdiction",
        value: data.jurisdiction.name,
      },
    ],
    [data]
  );

  return (
    <Wrapper className={className}>
      {info.map((item) => (
        <Info key={item.value}>
          {item.title}
          <Value>{item.value}</Value>
        </Info>
      ))}
    </Wrapper>
  );
};
