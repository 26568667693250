import styled from "styled-components";
import theme from "constants/theme";

export const Wrapper = styled.textarea`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: ${theme.color.mainLight};
  padding: 32px;
  background-color: ${theme.color.backgroundGray};
  border-radius: 12px;
  margin-bottom: 32px;
  width: 100%;
  border: none;
  outline: none;
  resize: none;
`;

export const Title = styled.div`
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: ${theme.color.mainDark};
  margin-bottom: 8px;
`;
