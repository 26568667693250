import { ChangeEvent, useEffect, useState, VFC } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers, Contract } from "ethers";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";
import { COPY_TEXT_ICON } from "constants/images";
import { useAuth, useToast } from "hooks";
import CopyAddress from "pages/JunaSimple/components/CopyAddress";
import {
  DescriptionTitle,
  Ipfs,
  StyledDescription,
  DescriptionBlock,
  IpfsText,
  DescriptionText,
  DescriptionWrap,
  Input,
  StyledConnectBtn,
  Error,
  ShareWrap,
  WrapCopyBtn,
} from "pages/JunaSimple/style";
import type { PieChartData } from "pages/JunaSimple/types";
import { Api } from "typings";
import { ReadTable } from "./components/ReadTable";

type Props = {
  contract: Api.Contract;
};

const IPFS_MOCK =
  "ipfs://5f381da2546906c21d47467cb54bd4e02bfd6b81997b4bec99f2fa296c32d26d";

const PIE_CHART_COLORS = [
  "#FF584B",
  "#4D9BFF",
  "#C83EF3",
  "#FFD265",
  "#fff",
  "#000",
];

const Read: VFC<Props> = (props) => {
  const { contract } = props;

  const [pieChartValues, setPieChartValues] = useState<PieChartData>([]);

  const [etherContract, setEtherContract] = useState<Contract | null>(null);

  const [addressSearchValue, setAddressSearchValue] = useState<string>("");

  const [isSearching, seIsSearching] = useState<boolean>(false);

  const [searchError, setSearchError] = useState<boolean>(false);

  const web3 = useWeb3React();

  const { account, isAuthorized, toggleProviderModal } = useAuth();

  const { t } = useTranslation();

  const toast = useToast();

  const handleAddressSearchChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setAddressSearchValue(evt.target.value);
  };

  useEffect(() => {
    setSearchError(false);

    const getShareholders = async () => {
      if (account && addressSearchValue && contract.code) {
        seIsSearching(true);

        const provider = new ethers.providers.Web3Provider(
          web3.library.provider
        );

        const etherContractInstance = new ethers.Contract(
          addressSearchValue,
          contract.code.abi,
          provider.getSigner(account).connectUnchecked()
        );

        try {
          const shareholders: string[] =
            await etherContractInstance.getShareholders();

          const shares = await Promise.all(
            shareholders.map(async (_, idx: number) => {
              const share = await etherContractInstance.shares(idx);
              return Number(share) / 100;
            })
          );

          const result = shareholders.reduce<PieChartData>(
            (acc, address, idx) => {
              acc.push({
                title: address,
                value: shares[idx],
                color: PIE_CHART_COLORS[idx],
              });
              return acc;
            },
            []
          );
          setEtherContract(etherContractInstance);
          setPieChartValues(result);
        } catch (err) {
          console.log("err", err);
          setSearchError(true);
          setEtherContract(null);
          setPieChartValues([]);
        } finally {
          seIsSearching(false);
        }
      }
    };

    account && getShareholders();
  }, [account, addressSearchValue, contract, contract.code, web3]);

  const getRewards = async () => {
    if (etherContract) {
      try {
        await etherContract.getRewards();
      } catch (err) {
        return toast.error({
          message: t("junaSimple.readPage.message"),
          title: t("junaSimple.readPage.errorTitle"),
        });
      }
    }
  };

  return (
    <>
      {addressSearchValue && etherContract && (
        <WrapCopyBtn>
          <CopyAddress address={addressSearchValue} />
        </WrapCopyBtn>
      )}
      {isAuthorized ? (
        <Input
          contract={etherContract}
          onChange={handleAddressSearchChange}
          placeholder="Enter address..."
          type="text"
        />
      ) : (
        <StyledConnectBtn onClick={toggleProviderModal} />
      )}
      {isSearching && <Loader />}
      {searchError && <Error>{t("junaSimple.readPage.error")}</Error>}
      {!isSearching && etherContract && isAuthorized && !searchError && (
        <>
          <Ipfs>
            <IpfsText>{IPFS_MOCK}</IpfsText>
          </Ipfs>
          <StyledDescription>
            <DescriptionTitle>
              {t("junaSimple.readPage.description")}
            </DescriptionTitle>
            <ShareWrap onClick={getRewards}>
              <img alt="#" src={COPY_TEXT_ICON} />
              <div>{t("junaSimple.readPage.share")}</div>
            </ShareWrap>
          </StyledDescription>
          <DescriptionWrap>
            <DescriptionBlock>
              <DescriptionText>
                {t("junaSimple.readPage.descriptionText")}
              </DescriptionText>
            </DescriptionBlock>
          </DescriptionWrap>
          <ReadTable chartData={pieChartValues} />
        </>
      )}
    </>
  );
};

export default Read;
