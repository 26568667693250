import styled from "styled-components";
import theme from "constants/theme";

export const Title = styled.span`
  font-weight: 800;
  font-size: 48px;
  line-height: 65px;
  letter-spacing: 0.02em;
  color: ${theme.color.mainDark};
`;

export const Case = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 80px;
  width: 100%;
  border: 1px solid ${theme.color.borderGray};
  border-radius: 12px;
  padding: 24px 32px;
  box-shadow: 0px 4px 8px rgba(226, 226, 238, 0.48);
  margin-bottom: 24px;

  @media screen and ${theme.device.tablet} {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
`;

export const Items = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  :not(:nth-last-child(1)) {
    margin-bottom: 12px;
  }

  @media screen and ${theme.device.laptop} {
    flex-direction: column;
  }
`;

export const Label = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${theme.color.mainDark};

  @media screen and ${theme.device.tablet} {
    font-size: 15px;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

export const InputsWrapper = styled.div`
  width: 238px;
`;
