import styled from "styled-components";
import theme from "constants/theme";

export const Title = styled.p`
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${theme.color.mainDark};
  padding-left: 22px;
  margin: 0 0 37px 0;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 20px 80px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
`;

export const Img = styled.img``;
