import theme from "constants/theme";

export const colourStyles = {
  container: (styles: any) => ({
    ...styles,
    width: "100%",
    outline: "none",
    border: "none",
  }),
  control: (styles: any) => ({
    ...styles,
    borderRadius: 50,
    backgroundColor: theme.color.backgroundGray,
    border: "none",
    outline: "none",
    padding: "2px 10px",
    fontSize: "14px",
  }),
  option: (styles: any) => {
    return {
      ...styles,
    };
  },
  placeholder: (styles: any) => ({
    ...styles,
    color: theme.color.mainLight,
  }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    display: "none",
  }),
  indicatorsContainer: (styles: any) => ({
    ...styles,
  }),
};
