import styled from "styled-components";
import theme from "constants/theme";

export const StyledContainer = styled.div`
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;

  @media screen and ${theme.device.desktop} {
    padding: 0 16px;
  }
`;
