import { VFC, useState } from "react";
import { Api } from "typings";
import CodeIcon from "./CodeIcon";
import { BtnWrapper, CodeWrapper, Code, Label, Overflow, StyledBtn, TableWrapper } from "./style";
import { DisplayMode } from "./types";
import VersionTable from "./VersionTable";

type Props = {
  abi?: string;
  code?: string;
  versions?: Api.Version[];
};

/**
 * Contract Code - block with the code of the current contract
 *
 * @param abi - abi contract information
 * @param code - code contract information
 *
 * @returns - TSX element (Contract Code component)
 */

const ContractCode: VFC<Props> = (props) => {
  const { abi, code, versions } = props;

  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.Code);

  const switchDisplayMode = (mode: DisplayMode) => setDisplayMode(mode);

  return (
    <>
      <BtnWrapper>
        <StyledBtn
          color={displayMode === DisplayMode.Code ? "red" : "white"}
          onClick={() => switchDisplayMode(DisplayMode.Code)}
        >
          <CodeIcon />
        </StyledBtn>
        <StyledBtn
          color={displayMode === DisplayMode.Text ? "red" : "white"}
          onClick={() => switchDisplayMode(DisplayMode.Text)}
        >
          ABI
        </StyledBtn>
      </BtnWrapper>
      <CodeWrapper>
        {displayMode === DisplayMode.Code && (
          <Code displayMode={displayMode}>
            <Overflow>
              <Label>{code}</Label>
            </Overflow>
          </Code>
        )}
        {displayMode === DisplayMode.Text && (
          <TableWrapper>
            <Code displayMode={displayMode}>
              <Overflow>
                <Label>{abi}</Label>
              </Overflow>
            </Code>
            <VersionTable versions={versions} />
          </TableWrapper>
        )}
      </CodeWrapper>
    </>
  );
};

export default ContractCode;
